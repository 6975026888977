.side_menu {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 20px;
}

.dropdown-menu {
  background: #181272;
}

.dropdown-menu .dropdown-item {
  color: white;
  margin: 0;
}

.dropdown-menu .dropdown-item:hover {
  color: white;
  margin: 0;
  background-color: #007bff;
}

#menu-uni .dropdown-item:hover {
  color: white;
  margin: 0;
  background-color: #007bff;
}
