.doc-primary {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}

.doc-secondary {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}

.doc-primary-1 {
    color: #fff;
    background-color: #8FAADC;
    border-color: #8FAADC;
}

.doc-success {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
}

.doc-danger {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
}

.doc-pending {
    color: #fff;
    background-color: #f0ad4e;
    border-color: #f0ad4e;
}

.status-styles {
    margin: 0;
    padding: .375rem .75rem;
    border-radius: 0.25rem;
    width: auto;
    text-align: center;
    text-transform: capitalize;
    white-space: break-spaces;
}


.tag-inProgress {
    color: #fff;
    background-color: #f2b01c;
    border-color: #f2b01c;
}

.accordion>.card .header-tag {
    background: rgb(202, 231, 246);
    color: black;
    width: 100% !important;
    font-size: 15px;
    cursor: pointer;
    border-radius: 10px;
    padding-left: 15px !important;
}

.accordion>.card .header-tag:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
}


.accordion>.card .header-view-category {
    background-color: #fff;
    border: 0 !important;
}

.accordion>.card .header-delete-record {
    color: #fff !important;
}

.large-button {
    font-size: 13px;
    font-weight: 600;
}

.neddHelpContainer {
    display: flex;
    width: 70%;
    justify-content: center;
    margin: auto;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.04);
    margin-top: 40px;
    flex-direction: column;
}

.card-body-space-between {
    width: 60%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
}

.card-body-space-between .form-group {
    display: flex;
    justify-content: space-between;
}

.disabled-link {
    background-color: red;
    cursor: not-allowed;
    pointer-events: none;
}

.student-360-tabs {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.custom-nav-link {
    /* width: calc(50% - 10px);
margin-bottom: 10px; */
    width: 100px;

}

@media (max-width: 768px) {
    .custom-nav-link {
        /* display: flex; */
        justify-content: center;
        width: 150px;
    }
}

.student-360-student-search-card {
    box-shadow: 2px 2px 10px 1px #ddd;
}

.student-360-student-search-card .card-body {
    padding: 20px 0 !important;
}

.student-360-student-details-card {
    margin-top: 20px;
    border-top: 1px solid #ddd !important;
    border: none;
    border-radius: 0;
}

.student-360-tabs .nav-item .nav-link {
    color: #fff !important;
    font-size: 13px;
    width: 200ps !important;
    justify-content: center;
}

.student-360-tabs .nav-item {
    width: 117px !important;

    margin-right: 0px !important;
}

.student-360-student-details-card .student-360-student-details-image-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.sidebar-nav .metismenu li ul {
    display: none;
}

.sidebar-nav .metismenu li ul .active {

    background-color: #2f35a8 !important;
    /* margin-left: 8%;
    margin-right: 8%; */

    /* text-align: center !important; */
}

.sidebar-nav .metismenu .removeActive {

    background-color: transparent !important;
    color: #fff !important;

}

.sidebar-nav .metismenu .open .hidden {
    display: flex;
}

.sidebar-nav .metismenu .open .hidden a::before {
    content: "";
}

.sidebar-nav .metismenu .open .hidden .active {
    margin-top: 10px;

    color: #ddd !important;
    font-weight: bolder;
    /* padding: 3px 7px; */
    transition: all ease-in-out;
}

.univertity-report-card-wrapper .fee-due-name-amount {
    width: calc(100% - 70px);
}

.univertity-report-card-wrapper .fee-due-icon {
    width: 50px;
}

.full-width-wrapper {
    display: flex;
    flex-wrap: wrap;
    /* align-items: center; */
    margin-bottom: 5px;
}

.univertity-report-card-wrapper .my_sort_cut i {
    font-size: initial;
}

.full-width-wrapper .left-wrapper,
.full-width-wrapper .right-wrapper {
    width: 100%;
}

.full-width-wrapper .left-wrapper {
    margin-bottom: 2px;
}

/* .univertity-report-card-wrapper .fee-due-container{
    align-items: center;
} */

.report-card-banner-wrapper .my_sort_cut:hover i,
.report-card-banner-wrapper .my_sort_cut i {
    font-size: initial;
}

.btnss:hover {
    color: black;
}

.card-in {
    background-color: rgb(79, 79, 223);
    color: white;
}

.table-in {
    background-color: #eae7e7 !important
}

.card-body { 
    padding: 12px !important;
}

.pb-0 {
    padding-bottom: 0 !important;
  }

.card-header {
    padding: 12px 0 !important;
}

.table-class * {
    background-color: #eae7e7 !important;
    background-color: rgb(193, 193, 203);
}