@import './assets/plugins/bootstrap/css/bootstrap.min.css';
@import './assets/css/style.min.css';
@import './assets/css/default.css';
@import './assets/plugins/nestable/jquery-nestable.css';

.student-360-tabs {
    gap: 10px;
    /* border: 1px solid #e7e9ed; */
    border-radius: 5px;
    padding: 5px 5px;
    margin: 0;
    width: 100%;
}

.student-360-tabs .nav-item {
    background: #181272;
    color: #fff;
    border-radius: 40px 5px 0 0;
    flex: 1;
    text-align: center;
    padding: 0;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    height: 30px;
}

.student-360-tabs .nav-item .nav-link {
    font-size: 13px;
}

.student-360-tabs .nav-item:hover>.nav-link {
    color: #fff;
}


.student-360-tabs .nav-item.active {
    background: linear-gradient(145deg, #181272, #1595CB) !important;
    color: #000000 !important;
}

.student-360-tabs .nav-item.active>.nav-link {
    color: #fff !important;
}

.cursor-pointer {
    cursor: pointer;
}

.payments-tnc-modal {
    @media screen and (min-width: 1024px) {
        .modal-dialog {
            max-width: 750px;
        }
    }
}

.material-body iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}