.metismenu li.active>a {
    color: #2185d0
}

.react-datepicker-wrapper {
    display: block !important;
}

.card-remove {
    display: none;
}


/* .circleCenter{margin-left: 90px;} */

.dLang {
    position: absolute;
    will-change: transform;
    transform: translate3d(-156px, 32px, 0px);
    top: 0;
    left: 0;
}

.dMail {
    position: absolute;
    will-change: transform;
    transform: translate3d(-312px, 32px, 0px);
    top: 0;
    left: 0;
}

.dNoti {
    position: absolute;
    will-change: transform;
    transform: translate3d(-282px, 32px, 0px);
    top: 0;
    left: 0;
}

.dProfile {
    position: absolute;
    will-change: transform;
    transform: translate3d(-77px, 32px, 0px);
    top: 0;
    left: 0;
}

.d3Dot {
    position: absolute;
    will-change: transform;
    top: 0px;
    left: 0px;
    transform: translate3d(-174px, -292px, 0px);
}

.dActivity {
    position: absolute;
    will-change: transform;
    top: 0px;
    left: 0px;
    transform: translate3d(-174px, 25px, 0px);
}

.notificationReaded {
    background-color: #f0f2f5;
}

input[class='coverpage'] {
    display: none;
}

input[class='coverpage'][type=file]::-webkit-file-upload-button {
    display: none;
}

input[class='coverpage'][type=file]::file-selector-button {
    display: none;
}

input[class='coverpage']:hover label {
    background-color: #dde0e3;
    cursor: pointer;
}

.status-styles {
    margin: 0;
    padding: .375rem .75rem;
    border-radius: 0.25rem;
    /* width: auto; */
    text-align: center;
    text-transform: capitalize;
}

@media screen and (max-width: 992px) {
    .search-filter {
        display: flex;
        row-gap: 15px;
    }
}

@media screen and (width > 992px) {
    .arrange-search {
        max-width: 14.3%;
    }
}

.blink_text {
    animation: blinker 2s linear infinite;
    font-weight: bolder;
  }

  @keyframes blinker {
    50% { opacity: 0; }
  }